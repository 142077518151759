<template>
  <div class="passwordReset" >
    <div class="container">
      <h1 v-if="!emailsent" class="gavc-h1 text-center">Réinitialisation de votre mot de passe</h1>
      <div v-if="emailsent">
        <div class="text-center">
          <div class="mail-sent-img">
            <b-img :src="require('@/assets/images/send.svg')" style="max-width: 15%;'"
                   fluid alt="Message envoyé"  />
          </div>
          <h3 class="gavc-h3 mt-3">
            Un email de réinitialisation de votre mot de passe vient d'être envoyé.
          </h3>
          <p class="gavc-chapo">
            Vérifiez également que l'email n'a pas atterri dans
            vos spams (courrier indésirable).
          </p>
        </div>
        <div class="row mt-3">
          <div class="col-12 text-center">
            <button class="gavc-btn" @click="$router.push('/')" style="white-space: normal;">
              Retour à l'accueil
            </button>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="gavc-chapo mt-4">
          Entrez l'email associé à votre compte pour recevoir le lien de réinitialisation
        </p>

        <b-form v-on:submit.prevent="resetPassword()" class="mt-4">
          <div
            class="gavc-field"
            v-bind:class="{ 'is-error': $v.resetPasswordEmail.$dirty ? $v.resetPasswordEmail.$error : null  }">
              <label class="gavc-label" for="reset-password-email-input">
                  Votre email
                  <span class="gavc-required"> * </span>
              </label>
              <input
                class="gavc-input"
                id="reset-password-email-input"
                placeholder="Email"
                required
                type="text"
                v-model="resetPasswordEmail">
          </div>
          <p class="gavc-p gavc-text-danger">
            <small v-if="errorEmailMessage" class="text-danger"> {{errorEmailMessage}} </small>
          </p>
          <div class="text-center">
            <button
              class="gavc-btn"
              :disabled="$v.resetPasswordEmail.$invalid || isResettingPassword"
              type="submit"
            >
              Réinitialiser mon mot de passe
            </button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, email, minLength, maxLength } from 'vuelidate/lib/validators'

import { APIDisconnectedService } from '@/api/APIDisconnected'

const apiAuth = new APIDisconnectedService()


export default {
  name: 'PasswordReset',
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      emailsent: false,
      errorEmailMessage: '',
      isResettingPassword: false,
      resetPasswordEmail: ''

    }
  },
  validations: {
    resetPasswordEmail: {
      required,
      email,
      maxLength: maxLength(200),
      minLength: minLength(2)
    }
  },

  methods: {
    resetPassword () {
      this.isResettingPassword = true
      this.$store.commit('loadFromAPI')
      apiAuth.resetPassword(this.resetPasswordEmail)
      .then(() => {
          this.emailsent = true
        }, (error) => {
          if (error.response.status == 400) {
            this.errorEmailMessage = error.response.data.email[0]
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isResettingPassword = false
      })
    },

    goHome () {
      this.$router.push('/')
    },
  }
}
</script>
